import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-memphis-tennessee.png'
import image0 from "../../images/cities/scale-model-of-mud-island-river-park-in-memphis-tennessee.png"
import image1 from "../../images/cities/scale-model-of-memphis-escape-rooms-in-memphis-tennessee.png"
import image2 from "../../images/cities/scale-model-of-beale-street-entertainment-district-in-memphis-tennessee.png"
import image3 from "../../images/cities/scale-model-of-backbeat-tours-in-memphis-tennessee.png"
import image4 from "../../images/cities/scale-model-of-number-1-party-bike-river-city-pedalers-in-memphis-tennessee.png"
import image5 from "../../images/cities/scale-model-of-cheetah-in-memphis-tennessee.png"
import image6 from "../../images/cities/scale-model-of-sidewalk-of-stars-in-memphis-tennessee.png"
import image7 from "../../images/cities/scale-model-of-cooper-young-historic-district-in-memphis-tennessee.png"
import image8 from "../../images/cities/scale-model-of-mississippi-river-museum-in-memphis-tennessee.png"
import image9 from "../../images/cities/scale-model-of-elvis-statue-in-memphis-tennessee.png"
import image10 from "../../images/cities/scale-model-of-memphis-rock-'n'-soul-museum-in-memphis-tennessee.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Memphis'
            state='Tennessee'
            image={image}
            lat='35.1495343'
            lon='-90.0489801'
            attractions={ [{"name": "Mud Island River Park", "vicinity": "125 N Front St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.148979, "lng": -90.0587913}, {"name": "Memphis Escape Rooms", "vicinity": "530 S Main St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1329879, "lng": -90.05875300000002}, {"name": "Beale Street Entertainment District", "vicinity": "Beale St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1397739, "lng": -90.0526903}, {"name": "Backbeat Tours", "vicinity": "197 Beale St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1397509, "lng": -90.05338069999999}, {"name": "Number 1 Party Bike River City Pedalers", "vicinity": "310 Beale St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1396755, "lng": -90.0506909}, {"name": "Cheetah", "vicinity": "1986 Prentiss Pl, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1506743, "lng": -89.99530249999998}, {"name": "Sidewalk Of Stars", "vicinity": "53 Beale St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.140274, "lng": -90.05494770000001}, {"name": "Cooper Young Historic District", "vicinity": "2120 Young Ave, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1196417, "lng": -89.99097599999999}, {"name": "Mississippi River Museum", "vicinity": "125 N Front St, Memphis", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.1493785, "lng": -90.05836349999998}, {"name": "Elvis Statue", "vicinity": "115 Beale St, Memphis", "types": ["point_of_interest", "establishment"], "lat": 35.1399782, "lng": -90.05427889999999}, {"name": "Memphis Rock 'n' Soul Museum", "vicinity": "191 Beale St, Memphis", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.1385285, "lng": -90.05216339999998}] }
            attractionImages={ {"Mud Island River Park":image0,"Memphis Escape Rooms":image1,"Beale Street Entertainment District":image2,"Backbeat Tours":image3,"Number 1 Party Bike River City Pedalers":image4,"Cheetah":image5,"Sidewalk Of Stars":image6,"Cooper Young Historic District":image7,"Mississippi River Museum":image8,"Elvis Statue":image9,"Memphis Rock 'n' Soul Museum":image10,} }
       />);
  }
}